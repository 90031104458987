import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/workspace/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Link } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "architecture"
    }}>{`Architecture`}</h1>
    <p>{`Ocelot is composed of three services, namely:`}</p>
    <ul>
      <li parentName="ul">{`The `}<strong parentName="li">{`Ocelot Middleware`}</strong>{` gets invoked by the API Gateway(s) to inspect every incoming request. Ocelot decides whether to reject or accept the connection based on a set of rules defining the security policy for the attempted business operation.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`Ocelot Web`}</strong>{` is an optional component of the internal tool meant to be used by IT Security staff at STARK. This interface offers an overview of the current distributed security policy. Given the required roles, you can also set up resources, clients, users, and much more to configure the Ocelot Middleware run-time behavior.`}</li>
      <li parentName="ul">{`The `}<strong parentName="li">{`Ocelot GitOps Operator`}</strong>{` is an optional component that allows developers in the teams to transparently update and define security policy for their services and their services alone, without the need to access the Ocelot Web and to have any access to the Ocelot Middleware run-time configuration.`}</li>
    </ul>
    <p><img alt="Architecture" src={require("../drawings/ocelot-architecture.svg")} /></p>
    <p>{`This architecture allows complete parallelization of the work performed by the development and security teams.
Ocelot's main objective is to ensure the two functions can work independently without friction, delays, and bottlenecks.`}</p>
    <p>{`For this reason, together with the Ocelot middleware, performing the actual enforcement of the distributed
security policy, two other optional components are available:`}</p>
    <p>{`The `}<em parentName="p">{`Ocelot Web`}</em>{` Application provides an overview to
auditors, security specialists, and architects interested in analyzing the current security status of the applications.
There is no need to keep your documentation updated or to reserve full days of work for a full-scale investigation; all
the information is fully available and up to date, ready to be analyzed.`}</p>
    <p>{`The `}<em parentName="p">{`Ocelot GitOps Operator`}</em>{`  enables the developers to define the part of the security policies concerning their
application without needing to learn a new tool and without gaining access to any security system.`}</p>
    <h2 {...{
      "id": "how-it-works"
    }}>{`How it works`}</h2>
    <p>{`To explain how the components interact together, we will go through specific situations and scenarios.`}</p>
    <h3 {...{
      "id": "user-traffic"
    }}>{`User traffic`}</h3>
    <p>{`For the end-users of your application, Ocelot is fully transparent. They will not know this solution protects your
applications. You are in complete control of the User Experience.`}</p>
    <p>{`However, Ocelot analyzes each API call the frontend applications execute against your API Gateway. If deemed legit,
the request is allowed to proceed to the business services carrying a new token forged specifically and unequivocally
to that single business operation, ensuring the enforcement of the `}<em parentName="p">{`Principle of Least Privilege`}</em>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Users and programs should only have the necessary privileges to complete their tasks.`}</p>
    </blockquote>
    <h3 {...{
      "id": "operator-traffic"
    }}>{`Operator traffic`}</h3>
    <p>{`With the word `}<inlineCode parentName="p">{`Operator`}</inlineCode>{` we address the security personnel allowed to operate on Ocelot, either viewing or
updating the distributed security policy it enforces.`}</p>
    <p>{`An Operator will interact with Ocelot either through its UI (human Operator) or through its APIs (Automation).`}</p>
    <p>{`An example of a human Operator is an Auditor; a professional who is in charge of regularly checking compliance and
fulfillment of internal or industry requirements.`}</p>
    <p>
An example of an automation Operator interfacing with Ocelot through its APIs is the Ocelot GitOps Operator, an operator
developed by YanchWare that offers <Link to="/src-markdowns-1-welcome#authorization-as-code" mdxType="Link">Authorization-as-Code</Link>
    </p>
    <h3 {...{
      "id": "gitops-operator-traffic"
    }}>{`GitOps Operator traffic`}</h3>
    <p>{`The GitOps Operator traffic originates from a webhook registered on your Source Control System
(GitHub, Azure DevOps, etc.). It will be present exclusively if you have opted to use the Ocelot GitOps Operator.`}</p>
    <p>{`The webhook notifies the Operator that something has changed in the source control system;
Based on its configuration, the Operator will decide which action to perform in response to the notification.
If the branch and repository have been set up to trigger updates on Ocelot, the Operator will clone the branch,
look for Ocelot configuration files, and apply them if found to be aligned with the authorization and validity checks.`}</p>
    <h2 {...{
      "id": "extendability"
    }}>{`Extendability`}</h2>
    <p>{`Ocelot has been developed with the "API-first" mantra always on our minds.`}</p>
    <p>
If you would like to integrate it with other tools or automation components present in your IT landscape you will just
have to use those APIs to do it. You can find more info on how to do just that in the <Link to="/src-markdowns-5-2-api-docs" mdxType="Link">API-Docs</Link> section of this document.
    </p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      